<template>
  <!-- Tab Section -->
  <section class="tab-section">
    <!-- Tab -->
    <!-- // Tab -->
    <!-- Tab Contents -->
    <router-view
      @input="onTabInput"
      @reservation="onReservation"
      :rooms="rooms"
      :startTime="startTime"
      :endTime="endTime"
      :isReservation="isReservation"
    ></router-view>
    <!-- Tab Contents -->
  </section>
</template>
<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';

export default {
  name: 'EventApplyAndHistory',
  mixins: [mixinHelperUtils],
  props: {
    rooms: {
      type: Array,
      // required: true,
    },
    startTime: {
      type: Object,
      // required: true,
    },
    endTime: {
      type: Object,
      // required: true,
    },
  },
  metaInfo() {
    return {
      meta: this.$route.meta.metaInfo
    };
  },
  data() {
    return {
      tabValue: undefined,
      isReservation: false,
    };
  },
  created() {
    const { path } = this.$route;
    const pathMatch = [/\/apply$/, /\/history$/];
    pathMatch.some((v, i) => {
      const bool = !!path.match(v);
      if (bool) {
        this.tabValue = i;
      }
      return bool;
    });
  },
  watch: {
    tabValue(newValue, oldValue) {
      if (oldValue !== undefined) {
        const params = {
          rooms: this.rooms,
          startTime: this.startTime,
          endTime: this.endTime,
          isReservation: this.isReservation,
        };
        if (newValue === 0) {
          this.$router.replace({ name: 'ReservationApply', params });
        } else {
          this.$router.replace({ name: 'ReservationHistory', params });
        }
      }
    },
  },
  methods: {
    onTabInput(value) {
      this.tabValue = value;
    },
    onReservation() {
      this.isReservation = true;
    },
  },
};
</script>
